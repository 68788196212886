/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-unveils-unprecedented-next-generation-hearing-aid-horizon-ix",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-unveils-unprecedented-next-generation-hearing-aid-horizon-ix",
    "aria-label": "hearcom unveils unprecedented next generation hearing aid horizon ix permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com unveils unprecedented next-generation hearing aid, Horizon IX"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "(Miami, Florida)"), " – After many years of research and input from millions of consultations, hear.com is excited to announce the launch of the ", React.createElement(_components.strong, null, "Horizon IX"), " hearing aid, the first hearing device designed exclusively for active, younger people. Founders ", React.createElement(_components.strong, null, "Dr. Marco Vietor"), " and ", React.createElement(_components.strong, null, "Paul Crusius"), " co-developed this unique, next-generation hearing device in partnership with German engineers from Signia, a top global hearing aid manufacturer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Geared toward active baby boomers who want to improve their quality of life, the sleek, sexy and nearly invisible IX hearing aids boast cutting-edge features and unparalleled power. This new device uses a unique speech-enhancing algorithm that is clinically proven to deliver better-than-normal hearing. In addition to ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth connectivity"), " and wireless, portable rechargeability that lasts up to 19 hours, the Horizon IX showcases two unprecedented, groundbreaking features controlled through a smartphone app:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Speech Focus — Users can rise above the noise in any environment with a new, unmatched level of speech clarity because they can now narrowly control how their hearing aids focus on sound direction."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Relax Mode — Users can unwind when they need it most with an ambient soundscape of the sea streamed right through the hearing aids."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "“Never before in this industry have the insights from experts, engineers and millions of hearing consultations been used to address the unique needs of active people with hearing loss. We’ve truly created a listening experience that’s second to none, said Crusius."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Horizon IX hearing aid is now available for purchase exclusively through hear.com and their Partner Network of over 3,000 hearing care professionals in the USA. Visit ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon",
    className: "c-md-a"
  }, "www.hear.com/hearing-aids/horizon/"), " for details."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "About hear.com")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com is the largest online retailer of medical-grade hearing aids in the world. Founded in Germany in 2012 and headquartered in Miami, hear.com has helped millions get on the path to better hearing by guiding customers through the hearing care process and educating them on hearing aid technology, styles, prices and more. hear.com was named a top customer service company by Newsweek in 2020 and has also been featured in publications like WebMD, Forbes, and Business Insider. Visit ", React.createElement(_components.a, {
    href: "/about-us",
    className: "c-md-a"
  }, "www.hear.com/about-us/"), " for more information."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
